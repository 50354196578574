@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.nav-link.active {
  color: #E91E63;
  /* Active text color */
}

.nav-link.active::after {
  width: 100%;
  /* Expand underline only for the hovered item */
}

.nav-link {
  position: relative;
  display: inline-block;
  color: #4B5563;
  /* Gray text */
  text-decoration: none;
  padding-bottom: 3px;
  margin: 0 10px;
  /* Optional: Adds spacing between items */
  transition: color 0.3s ease;
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #E91E63;
  /* Pink underline */
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: #E91E63;
  /* Change text color on hover */
}

.nav-link:hover::after {
  width: 100%;
  /* Expand underline only for the hovered item */
}

/* Hide scrollbars on Webkit browsers (Chrome, Safari, etc.) */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbars on other browsers (Firefox, Edge) */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* styles.css or your main CSS file */
.slick-prev,
.slick-next {
  font-size: 24px;
  color: #fff;
  /* Change color as needed */
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  border: none;
  /* Remove default border */
  border-radius: 50%;
  /* Rounded buttons */
  width: 40px;
  /* Width of the buttons */
  height: 40px;
  /* Height of the buttons */
  display: flex;
  /* Center the icon */
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* Ensure buttons are on top */
}

.slick-prev {
  left: 10px;
  /* Position from the left */
}

.slick-next {
  right: 10px;
  /* Position from the right */
}

.tooltip-custom {
  max-width: 150px;
  /* Adjust the width as needed */
  white-space: normal;
  /* Allow text to wrap */
  padding: 8px;
  /* Optional: Add some padding for better appearance */
}


.chat-bubble {
  background-color: var(--bubble-color);
  /* Ensure main bubble uses inline background color */
  color: inherit;
  /* Inherit text color as well */
  /* Subtle shadow for depth */
}

/* Tail effect */
.chat-bubble::after {
  content: '';
  position: absolute;
  bottom: -10px;
  /* Move the tail further down to make it longer */
  left: 20px;
  /* Adjust left position as needed */
  width: 0;
  height: 0;
  border: 15px solid transparent;
  /* Increase the border size */
  border-top-color: var(--bubble-color);
  /* Match tail color to bubble background */
  border-bottom: 0;
  border-left: 0;
  margin-left: -5px;
}


.carousel-wrapper {
  width: 100%;
  /* Full width */
  overflow: hidden;
  /* Hide overflow to prevent shivering */
  position: relative;
  /* Position relative for inner container */
}

.carousel {
  display: flex;
  animation: scroll 20s linear infinite;
  /* Adjust duration for speed */
}

.carousel-image {
  width: 300px;
  /* Set your image width */
  height: auto;
  /* Set your image height */
  margin-right: 30px;
  /* Space between images */
}

/* Keyframe for scrolling animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
    /* Start position */
  }

  100% {
    transform: translateX(-50%);
    /* Move left, adjust based on duplicated images */
  }
}